const isNewOrEditJobPage = window.location.pathname.match(/\/jobs\/(\d*\/edit|new)/);

if (isNewOrEditJobPage) {
  $(document).ready(function() {
    initializeSendEmailButton()
    $('#job_send_confirmation_email').on('change', function() {
      const emailSection = $('#confirmation_email_section')
      if (this.checked){
        emailSection.show();
      } else {
        emailSection.hide();
      }
    });

    $('#job_total_price,#job_day,#job_time').on('input', function() {
      $('#confirmation_email_li .email-message').remove()
      const totalPrice = $('#job_total_price').val();
      const day = $('#job_day').val();
      const time = $('#job_time').val();
      updateSendEmailButton(totalPrice, day, time)
    });
  });
}

function updateSendEmailButton(totalPrice, day, time) {
  const sendEmailBtn = $('#confirmation_email_btn');
  if (totalPrice && day && time) {
    sendEmailBtn.removeClass('disabled_btn')
    sendEmailBtn.addClass('enabled_btn')
    sendEmailBtn.off('click').on('click', handleEnabledSendEmailClick);
  } else {
    sendEmailBtn.removeClass('enabled_btn')
    sendEmailBtn.addClass('disabled_btn')
    sendEmailBtn.off('click').on('click', handleDisabledSendEmailClick);
  }
}

function initializeSendEmailButton(){
  const sendEmailBtn = $('#confirmation_email_btn');
  if (sendEmailBtn.hasClass('enabled_btn')) {
    sendEmailBtn.on('click', handleEnabledSendEmailClick);
  } else {
    sendEmailBtn.on('click', handleDisabledSendEmailClick);
  }
}

function handleDisabledSendEmailClick(event) {
  event.preventDefault()
}

function handleEnabledSendEmailClick(event) {
  event.preventDefault();
  const jobId = window.location.pathname.split('/')[3]

  const totalPrice = $('#job_total_price').val();
  const day = $('#job_day').val();
  const time = $('#job_time').val();
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const params = JSON.stringify({job: { total_price: totalPrice, day: day, time: time }})

  fetch(`/admin/jobs/${jobId}/send_confirmation_email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken
    },
    body: params
  })
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then(data => {
        throw new Error(data.message || 'Error');
      });
    }
  })
  .then(data => {
    showSendEmailResponse(data.message, 'success')
  })
  .catch(error => {
    showSendEmailResponse(error.message, 'error')
  });
}

function showSendEmailResponse(message, type) {
  $('#confirmation_email_li .email-message').remove()
  $('#confirmation_email_li').append(`<span class='email-message ${type}'>${message}</span>`)
}
